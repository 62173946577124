import ECharts from "vue-echarts";
/*
 import echarts                  from "echarts/lib/echarts";
 */
import walletService from "../../services/walletService";
import { mapActions, mapGetters } from "vuex";
import * as types from "../../store/types";
import "echarts/lib/chart/pie";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/grid";
import "echarts/lib/component/toolbox";

export default {
  name: "dashboard-charts",
  components: {
    "v-chart": ECharts
  },
  props: [],
  data() {
    return {
      hello: "text",
      pie: {
        /*  tooltip: {
                 trigger: 'item',
                 formatter: '{a} <br/>{b}: {c} ({d}%)'
                 }, */
        /*title : {
                 text : "مقادیر ارزها" ,
                 right : 15 ,
                 top : 15 ,
                 textStyle : {
                 align : "center" ,
                 color : "#8b8b8b" ,
                 fontStyle : "IRANSansLight"
                 }
                 } ,*/
        legend: {
          left: 15,
          top: 15,
          data: [],
          padding: [
            0, // up
            0, // right
            10, // down
            0 // left
          ],
          textStyle: {
            color: "var(--darker-fontColor)",
            fontFamily: "IRANSansLight"
          }
        },
        // tooltip: {
        //     trigger: "item",
        //     backgroundColor: "rgba(255,255,255,0.8)", //Set the background image rgba format
        //     color: "black",
        //     borderWidth: "1", //Border width setting 1
        //     borderColor: "gray", //Set the border color
        //     textStyle: {
        //       color: "black" //Set text color
        //     },
        //   },
        series: [
          {
            name: "pie",
            type: "pie",
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "16",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ]
      },
      bar: {
        xAxis: {
          type: "category",
          data: [],
          axisLine: {
            lineStyle: {
              color: `var(--darker-backgroundColor)`
            }
          },
          nameTextStyle: {
            color: "red"
          }
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: `var(--darker-backgroundColor)`
            }
          },
          axisLabel: {
            color: `var(--darker-backgroundColor)`
          }
        },
        series: [
          {
            data: [],
            type: "bar"
          }
        ]
        /*xAxis: {
                 type: "category",
                 data: [0, 10, 11, 12, 13, 14, 15, 16, 1393, 1393, 1394, 1395, 1396, 1397, 1398, 1399]
                 },
                 yAxis: {
                 type: "value"
                 },
                 series: [{
                 data: [0, 820, 932, 2000, 934, 1290, 1330, 1320, 0, 820, 932, 4240, 934, 1290, 1330, 1320],
                 type: "line",
                 smooth: true,
                 itemStyle: {
                 color: "#36f"
                 },
                 areaStyle: {
                 color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                 offset: 0,
                 color: "#36f"
                 }, {
                 offset: 1,
                 color: "rgba(51,102,255,.4)"
                 }])
                 }
                 }]*/
      }
    };
  },
  computed: {
    ...mapGetters({
      isWalletLoaded: types.GET_IS_WALLET_LOADED,
      wallets: types.GET_WALLET,
      currencies: types.GET_CURRENCIES
    }),
    renderPirChart() {
      let data = this.wallets;
      this.pie.series[0].data = [];
      this.pie.legend.data = [];
      (this.pie.tooltip = {
        trigger: "item",
        formatter: "{b} : <br/> {c} ({d}%)",
        backgroundColor: "rgba(255,255,255,0.8)",
        textStyle: {
          color: "black"
        }
      }),
        this.pie.series[0].data.push(
          {
            value: data.rialValue,
            name: this.$i18n.t("panel.merchant.dashboard.charts.finance"),
            itemStyle: {
              color: "#36f"
            }
          },
          {
            value: data.settledValue,
            name: this.$i18n.t("panel.merchant.dashboard.charts.withdrawal"),
            itemStyle: {
              color: "#ff3d71"
            }
          }
        );
      this.pie.legend.data.push(
        this.$i18n.t("panel.merchant.dashboard.charts.finance"),
        this.$i18n.t("panel.merchant.dashboard.charts.withdrawal")
      );
    },
    renderBarChart() {
      let data = this.wallets;
      this.bar.series[0].data = [];
      this.bar.xAxis.data = [];
      this.bar.series[0].data.push(
        {
          value: data.rialValue,
          name: this.$i18n.t("panel.merchant.dashboard.charts.finance"),
          itemStyle: {
            color: "#36f"
          }
        },
        {
          value: data.settledValue,
          name: this.$i18n.t("panel.merchant.dashboard.charts.withdrawal"),
          itemStyle: {
            color: "#ff3d71"
          }
        }
      );
      this.bar.xAxis.data.push(
        this.$i18n.t("panel.merchant.dashboard.charts.finance"),
        this.$i18n.t("panel.merchant.dashboard.charts.withdrawal")
      );
    }
  },
  mounted() {},
  created() {
    this.getWalletFunc();
  },
  methods: {
    ...mapActions({
      walletAction: types.ACTION_WALLET
    }),

    getWalletFunc() {
      walletService
        .getWallet(localStorage.getItem("bitrahAccessToken"))
        .then(response => {
          this.walletAction(response.data);
        })
        .catch(() => {
          this.$bvToast.toast(
            this.$i18n.t("panel.merchant.dashboard.charts.error"),
            {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false
            }
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
